import React from 'react'
import { PageProps } from 'gatsby'

import { BadgeCheckIcon } from '@heroicons/react/outline'
import Footer from '../components/Footer'
import Header from '../components/Header'
import ContentDetails from '../components/ContentDetails'

const mainFeatureItems = [
  {
    id: 1,
    name: `General Principles`,
    description:
      `The company and its employees will at all times demonstrate the highest levels of integrity, truthfulness, and honesty in order to uphold both personal and corporate reputations and to inspire confidence and trust in their respective actions. The company will conduct its business in a competent, fair, impartial, and efficient manner.`,
    icon: BadgeCheckIcon,
  },
  {
    id: 2,
    name: `Health and Safety`,
    description:
      `The company is committed to providing a safe and healthy working environment for all of its employees both on and off its sites. There is a programme of regular health and safety audits and safety training. The company applies its standards to all visitors to its sites.`,
    icon: BadgeCheckIcon,
  },
  {
    id: 3,
    name: `Environment`,
    description:
      `The company respects the environment and the need to protect it and minimise the impact its operations have on it. It is engaged in a continuous programme of improvement on environmental issues and opens itself to independent third party verification, inspection, and certification of its progress.`,
    icon: BadgeCheckIcon,
  },
  {
    id: 4,
    name: `Employees`,
    description:
      `All employees are treated with dignity and respect with equal employment opportunities given to all irrespective of their race, religion, gender, sexual orientation, maternity, marital status, family status, disability, age, or national origin. Employees are offered a safe and healthy workplace and the company will not tolerate any form of harassment.`,
    icon: BadgeCheckIcon,
  },
  {
    id: 5,
    name: `Customers`,
    description:
      `The company will take all reasonable care to avoid misleading statements, concealment, and overstatement in all of its advertising and public statements. It will seek to build long term partnerships with its customers by being honest and straightforward in its dealings at all times. It will respect the confidentiality of any information it may obtain in relation to its customers.`,
    icon: BadgeCheckIcon,
  },
  {
    id: 6,
    name: `Suppliers`,
    description:
      `Suppliers will be chosen on the basis of factors such as price, quality, delivery, service, and integrity. The company’s choice of suppliers will be made objectively. Honesty and openness will be paramount in the company’s dealings with its suppliers.`,
    icon: BadgeCheckIcon,
  },
  {
    id: 7,
    name: `Competitors`,
    description:
      `The company will build its reputation on the basis of its performance alone. It will compete vigorously and lawfully and will not compete unfairly with others. It will not seek to damage the reputation of its competitors either directly or by implication.`,
    icon: BadgeCheckIcon,
  },
  {
    id: 8,
    name: `Government, Regulators, and Legislators`,
    description:
      `The company will seek to comply with all international, national, and local legislation affecting its operations. It will strive to follow the best practice in corporate governance. It will meet its tax obligations. It will not make any financial contributions or offer support to any political party.`,
    icon: BadgeCheckIcon,
  },
  {
    id: 9,
    name: `Giving and Receiving Gifts and Entertainment`,
    description:
      `Employees will neither seek nor accept for themselves or others any gifts, favours, or entertainment without a legitimate purpose from any person or business organisation that does or seeks to do business with, or is a competitor of Sekyee Computer Ltd. Gifts, favours, and entertainment may be given to others at the expense of the company as long as these are consistent with customary business practice and are not excessive in value.`,
    icon: BadgeCheckIcon,
  },
  {
    id: 10,
    name: `Bribes and Corrupt Practice`,
    description:
      `The company does not allow the direct or indirect offer, payment, solicitation, or acceptance of bribes in any form. The company has a separate Bribery Policy in place which examines in detail the procedures all employees must follow to avoid involvement in any situation which might lead to the offer of bribes. The policy makes it clear that any employee found to be involved in any kind of corrupt practice is likely to be immediately dismissed and may well have committed a criminal act which could lead to prosecution.`,
    icon: BadgeCheckIcon,
  }
]

const EthicalPolicy = (props: PageProps) => {
  return (
    <div className="bg-white">

      <Header path={props.path} />

      <main>
        <ContentDetails 
          title="ETHICAL POLICY STATEMENT" 
          tagline="Sekyee Computer Ltd expects and demands that each of its business units, all of its employees, and its Directors carry out their business and perform their duties to the highest ethical standards and in compliance with all relevant legal principles. This standard of behaviour and performance is maintained in the company’s dealings with employees, customers, suppliers, and all other stakeholders."
          mainFeatures={mainFeatureItems} 
          footer="Please contact us for more information." />
      </main>
    
      <Footer />
    </div>
  )
}

export default EthicalPolicy